import { useEffect, useState } from "react";
import { Popover, Widget } from '@typeform/embed-react';

// Login State
import {
    isFGDUserState,
} from "state/atoms";
import { useRecoilState, useRecoilValue } from "recoil";

type Props = {
    pageTitle: string;
};

declare const window: any;

const Support = ({ pageTitle }: Props) => {
    const [showForm, setShowForm] = useState(false);
    let page: any;
    if (window.location.pathname.includes('articles/')) {
        page = 'Article - ' + pageTitle;
    } else if (window.location.pathname.includes('topics/')) {
        let path = window.location.pathname.split('/');
        page = 'Topic - ' + path[path.length - 1];
    } else {
        page = pageTitle;
    }

    // Feel Good Design Homepage
    const [isFGDUser, setFGDUser] = useRecoilState(isFGDUserState);

    if (isFGDUser) {
        return (
            <>
                <div className={'support-btn-container'}>
                    <Popover
                        id={'l05aM9OE'}
                        chat
                        hidden={{
                            page: page
                        }}
                        customIcon={'https://images.typeform.com/images/SsYbNaMy69Sy'}
                    />
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className={'support-btn-container'} onClick={() => {
                    console.log(window);
                }}>
                    {pageTitle === 'Homepage' ? (
                        <Popover
                            id="xx6pXuSI"
                            chat
                            tooltip="Hello. Please give us your feedback about the Marketing Exchange."
                            hidden={{
                                page: page
                            }}
                            customIcon={'https://images.typeform.com/images/SsYbNaMy69Sy'}
                        />
                    ) : (
                        <Popover
                            id="xx6pXuSI"
                            chat
                            hidden={{
                                page: page
                            }}
                            customIcon={'https://images.typeform.com/images/SsYbNaMy69Sy'}
                        />
                    )}
                </div>
                {/*{showForm && <SupportForm setShowForm={setShowForm}/>}*/}
            </>
        );
    }
};

export default Support;
