import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useRecoilState } from "recoil";
import { loginState, currentUserState, magicBellInitState } from "state/atoms";
import MagicBell, { FloatingNotificationInbox } from "@magicbell/magicbell-react";
import CustomNotification from './CustomNotification';
import {
  createMagicBellUser,
  updatedMagicBellUserByEmail,
  updatedMagicBellUserById,
  deleteMagicBellUser,
  deleteMagicBellUserByEmail,
  fetchNotifications
} from "helpers/functions";

import styles from "styles/nav/Nav.module.scss";
import IconNotification from "images/icon-notification.svg";

const theme = { "icon": { "borderColor": "#000000", "width": "24px" }, "unseenBadge": { "backgroundColor": "#db0007" }, "header": { "backgroundColor": "ffbc0d", "textColor": "#000000", "borderRadius": "4px" }, "footer": { "backgroundColor": "ffbc0d", "textColor": "#000000", "borderRadius": "4px" }, "notification": { "default": { "textColor": "#15091F", "borderRadius": "4px", "backgroundColor": "ffbc0d" }, "unseen": { "backgroundColor": "ffbc0d", "textColor": "#15091F", "borderRadius": "4px" }, "unread": { "backgroundColor": "ffbc0d", "textColor": "#15091F", "borderRadius": "4px" } } };

const apiKey = process.env.NEXT_PUBLIC_MAGICBELL_API_KEY;
const apiSecret = process.env.NEXT_PUBLIC_MAGICBELL_SECRET;

const userNotifications = async (params: any) => {
  const currentUser: any = params.currentUser;

  if (currentUser) {
    const email: any = currentUser.email;
    const id: any = currentUser.id;
    let deletePromise: any = [];
    // let deletedUser = await deleteMagicBellUser(46);
    // console.log('deletedUser', deletedUser);

    let results = await fetchNotifications(email);
    if(!results.error && results.res.notifications) {
      params.setNotifications(results.res.notifications);
    }

    // Make sure user has both id and email saved
    let updateUser = await updatedMagicBellUserById(email, id);
    if (updateUser.loaded && !updateUser.error) {
      params.setUserChecked(true);
    }
    if (updateUser.error) {
      updateUser = await updatedMagicBellUserByEmail(email, id);
    }
    if (updateUser.error) {
      let deletedUser = await deleteMagicBellUserByEmail(email);
      deletePromise.push(deletedUser);
      Promise.all(deletePromise).then((values: any) => {
        setTimeout(() => {
          updateUserEmail({
            email: email,
            id: id,
            updateUser: updateUser,
            setUserChecked: params.setUserChecked
          })
        }, 1000);
      });
    }
  }
}

const updateUserEmail = async (params: any) => {
  const email = params.email;
  const id = params.id;
  let updatesCount = 1;
  let updateUser = await updatedMagicBellUserById(email, id);
  updatesCount++;
  params.setUserChecked(true);
  while (updateUser.error) {
    updateUser = await updatedMagicBellUserById(email, id);
    updatesCount++;
    if (updatesCount >= 25) {
      updateUser.error = false;
    }
  }
}

const NotfiBell = () => {
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);
  const [magicInit, setMagicInit] = useRecoilState(magicBellInitState);
  const [userExists, setUserExists] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<any>([]);
  const [magicBellOpen, setMagicBellOpen] = useState<boolean>(false);
  const [defaultOpen, setDefaultOpen] = useState<boolean>(false);
  const [userChecked, setUserChecked] = useState<boolean>(false);
  useEffect(() => {
    userNotifications({
      currentUser: currentUser,
      setMagicBellOpen: setMagicBellOpen,
      userChecked: userChecked,
      setUserChecked: setUserChecked,
      setNotifications: setNotifications,
    });
  }, [currentUser]);

  useEffect(() => {
    if(notifications && notifications.length > 0 && !magicBellOpen && magicInit) {
      notifications.forEach((item: any) => {
        if(item && item.read_at && !defaultOpen) {
          setDefaultOpen(true);
        }
      })
    }
  }, [notifications]);

  useEffect(() => {
    if(magicInit) {
      setMagicInit(false);
    }
    // console.log('currentOpen', magicInit);
  }, [magicInit]);

  return (
    <>
      {userChecked ? (
        <MagicBell
          apiKey={apiKey}
          // userEmail={currentUser.email}
          userExternalId={`${currentUser.id}`}
          theme={theme}
          locale="en"
          defaultIsOpen={defaultOpen}
        >
          {(props) => {
            // console.log('magic bell props', props);

            return (
              <FloatingNotificationInbox
                width={400}
                height={500}
                NotificationItem={CustomNotification}
                {...props} />
            )
          }}
        </MagicBell>
      ) : (
        <></>
      )}
    </>
  )

}


export default NotfiBell;
