import React, {
    useState,
    useRef,
    useEffect,
    RefObject,
    MouseEventHandler,
} from "react";
import { IfaceMenuApi } from "helpers/interfaces";
import Link from "next/link";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { fetchMenu, slugify } from "helpers/functions";

// Styles
import styles from "styles/nav/Nav.module.scss";

// Images
import Close from "images/ui-plus-close.svg";
import Arrow from "images/ui-menu-arrow.svg";

// State
import {
    topicListState,
} from "state/atoms";

type Props = {
    menu: IfaceMenuApi | any;
    fgdMenu?: IfaceMenuApi | any;
    isFGDUser?: boolean;
};

const MenuItem = (props: any) => {
    const data: any = props.itemData;
    const [showSubMenu, setShowSubMenu] = useState(false);
    const isFGDMenu: boolean = props.isFGDMenu || false;
    const parent: any = props.parent || null;
    const [menuData, setMenuData] = useState<any>(props.itemData ? { ...props.itemData } : null);

    let ref = useRef<HTMLLIElement>(null);

    // useEffect(() => {
    //     const handler = (event: any) => {
    //         if (
    //             showSubMenu &&
    //             ref.current &&
    //             !ref.current.contains(event.target)
    //         ) {
    //             setShowSubMenu(false);
    //         }
    //     };
    //     document.addEventListener('mousedown', handler);
    //     document.addEventListener('touchstart', handler);
    //     return () => {
    //         // Cleanup the event listener
    //         document.removeEventListener('mousedown', handler);
    //         document.removeEventListener('touchstart', handler);
    //     };
    // }, [showSubMenu]);

    const toggleSubMenu = () => {
        showSubMenu
            ? setShowSubMenu(false)
            : setShowSubMenu(true);
    };

    const topics = useRecoilValue(topicListState);
    let sortedTopics: any = topics ? [...topics.res.data] : null;

    function compare(a: any, b: any) {
        if (a.title < b.title) {
            return -1;
        }
        if (a.title > b.title) {
            return 1;
        }
        return 0;
    }

    if (sortedTopics) {
        sortedTopics.sort(compare);
    }

    const [subMenu, setSubMenu] = useState<any>(null);
    const getSubMenu = async () => {
        let menuID: any = null;
        if (props.itemData.sub_menu_id) {
            menuID = props.itemData.sub_menu_id;
        } else if (props.itemData.sub_menu && props.itemData.sub_menu.id) {
            menuID = props.itemData.sub_menu.id;
        }
        if (menuID) {
            const menuRes: IfaceMenuApi = await fetchMenu(menuID);
            if (!menuRes.error && menuRes.res.data && menuRes.res.data.length > 0) {
                let updatedMenuData: any = { ...menuData };
                updatedMenuData.children = menuRes.res.data;
                setMenuData(updatedMenuData);
            }
        }
    }

    useEffect(() => {
        if (props.itemData && isFGDMenu && parent && (parent === 'Downloads' || parent === 'Guidelines')) {
            getSubMenu();
        }
    }, [showSubMenu]);

    return (
        <>
            {menuData.children && menuData.children.length > 0 ? (
                <li className={styles.parentItem} ref={ref}>
                    <button onClick={toggleSubMenu} aria-haspopup="menu" aria-expanded={showSubMenu} className={`${styles.parentMenuItem}`}>
                        {menuData.name}
                        <Arrow />
                    </button>
                    <ul className={`${styles.submenuList} ${showSubMenu ? styles.show : ''}`}>
                        {menuData.children.map((subItem: any, index: any) => {
                            if (subItem.enabled) {
                                return (
                                    <MenuItem
                                        key={`sub-menu-item-${index}`}
                                        itemData={subItem}
                                        prevToggle={setShowSubMenu}
                                        isFGDMenu={isFGDMenu}
                                        parent={menuData.name}
                                    />
                                )
                            } else {
                                return <></>
                            }
                        })}
                    </ul>
                </li>
            ) : (
                <li>
                    {menuData.url ? (
                        <>
                            {menuData.target === '_blank' ? (
                                <a href={`${menuData.url ? menuData.url : '#'}`} target={menuData.target} className={`${slugify(menuData.name)}`}>{menuData.name}</a>
                            ) : (
                                <Link href={`${menuData.url ? menuData.url : '#'}`}>
                                    <a className={`${slugify(menuData.name)}`}>{menuData.name}</a>
                                </Link>
                            )}
                        </>
                    ) : (
                        <span className={styles.parentHeader}>{menuData.name}</span>
                    )}
                </li >
            )}
        </>
    )
}

const FGDMenuList = (props: any) => {
    const fgdMenu: any = props.fgdMenu;
    const [showSubMenu, setShowSubMenu] = useState(false);

    const toggleSubMenu = () => {
        showSubMenu
            ? setShowSubMenu(false)
            : setShowSubMenu(true);
    };

    return (
        <li className={styles.menuParentItem} key={`menu-parent-item-fgd`}>
            <button onClick={toggleSubMenu} aria-haspopup="menu" aria-expanded={showSubMenu} className={`${styles.parentMenuItem}`}>
                Feel-Good Design
                <Arrow />
            </button>
            {fgdMenu.res.data.length > 0 && (
                <ul className={`${styles.submenuList} ${showSubMenu ? styles.show : ''}`}>
                    {fgdMenu.res.data.map((childItem: any, index: any) => {
                        if (childItem.enabled) {
                            return (
                                <MenuItem
                                    key={`fgd-menu-item-${index}`}
                                    itemData={childItem}
                                    isFGDMenu={true}
                                />
                            )
                        } else {
                            return <></>
                        }
                    })}
                </ul>
            )}
        </li>
    )
}

const MobileMenu = ({ menu, fgdMenu, isFGDUser = false }: Props) => {
    return (
        <ul className={styles.mobileNavList}>
            {!isFGDUser && menu.res && menu.res.data && menu.res.data.length > 0 && menu.res.data.map((parentItem: any, index: any) => {
                if (parentItem.enabled) {
                    return (
                        <MenuItem
                            key={`menu-item-${index}`}
                            itemData={parentItem}
                        />
                    )
                }
            })}
            {fgdMenu && fgdMenu.res && fgdMenu.res.data && fgdMenu.res.data.length > 0 && (
                <FGDMenuList fgdMenu={fgdMenu} />
            )}
        </ul>
    )
}

export default MobileMenu;