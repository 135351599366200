import React, { useState, useEffect } from "react";

// Next
import Link from "next/link";

// Styles
import styles from "styles/components/core-menu/Footer.module.scss";

// Images
import Logo from "images/core-menu-logo.svg";

const Footer = () => {
    const date: any = new Date();

    return (
        <footer id={'cmm-footer'} className={styles.footer}>
            <div className="wrapper">
                <div className={styles.row}>
                    <div className={styles.leftColumn}>
                        <Link href={'/globalcoremenu'}>
                            <a aria-label="Global Core Menu Standards and Principals">
                                <Logo />
                            </a>
                        </Link>
						<div className={styles.smallText}>
                    		<p className={styles.copyright}>&copy;Copyright&nbsp;{date.getFullYear()}</p>
							<div>
								<p><a href="/privacy">View the Marketing Exchange Privacy Statement</a>.</p>
								<p><a href="/terms">View Terms &amp; Conditions</a>. PLEASE CAREFULLY READ AND UNDERSTAND THESE TERMS OF USE. THEY CONTAIN LIMITATIONS ON McDONALD’S LIABILITY AND OTHER PROVISIONS THAT AFFECT YOUR LEGAL RIGHTS.</p>
							</div>
						</div>
                    </div>
                    <div className={styles.rightColumn}>
                        <div className={styles.listGrid}>
                            <ul>
                                <li>
                                    <a href={'/change-plan'}>Create Change Plan</a>
                                </li>
                                <li>
                                    <a href={'/products'}>Product Directory</a>
                                </li>
                                <li>
                                    <a href={'/contact'}>Contact</a>
                                </li>
                                <li>
                                    <a href={'/faq'}>FAQ</a>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <a href={'/governance-and-approval'}>Governance and Approval</a>
                                </li>
                                <li>
                                    <a href={'/protocols-for-managing-change'}>Protocols for Managing Change</a>
                                </li>
                                <li>
                                    <a href={'/operations-testing-protocols'}>Operations Testing Protocols</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <span className={styles.divider}></span>
                <div className={styles.footerBottom}>
                    <p>Confidential and Proprietary Information © 2024 McDonald’s</p>
                    <p>The McDonald’s Global Core Menu Manual is for the exclusive use of employees and licensees of the McDonald’s System and by authorized third parties for the benefit of McDonald’s and its licensees.</p>
                    <p>This material may not be copied and otherwise reproduced, distributed or disclosed to others without the express written consent of McDonald’s.</p>
                    <p>ANY UNAUTHORIZED USE OR COPYING OF THIS MATERIAL MAY LEAD TO CIVIL AND CRIMINAL PROSECUTION. All trademarks used herein are the property of McDonald’s Corporation and its affiliates.</p>
                    <p>All other trademarks are those of their respective owners.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;