import Layout from "components/global/Layout";
// global state
import { useRecoilState } from "recoil";
import { navbarColorState } from "../state/atoms";
// import fontStyles from "public/splashpage/css/fonts.css";
// import styles from "public/splashpage/css/style.module.scss";
// import styles from "styles/pages/SplashPage.module.scss";
// import Logo from "/splashpage/images/Marketing_Exchange_Logo_NoE-02.svg";
import Head from "next/head";

const SplashPage = () => {
    // Update Navbar Color
    const [navbarColor, setNavbarColor] = useRecoilState(navbarColorState);
    setNavbarColor("light");

    return (
        <div className={`kindleSplashPage`}>
            <Head>
                <title>Coming Soon — Marketing Exchange</title>
                <link
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
                    rel="stylesheet"
                    integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
                    crossorigin="anonymous"
                />
                <link href="/splashpage/css/fonts.css" rel="stylesheet" />
                <link href="/splashpage/css/style.css" rel="stylesheet" />
                <script
                    defer
                    src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
                    crossorigin="anonymous"></script>
            </Head>
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-2 mt-4 mb-4">
                        <img
                            src="/splashpage/images/logo-me.png"
                            className="img-fluid"
                        />
                    </div>
                </div>
            </div>

            <div className="container-fluid splash-bg">
                <div className="row">
                    <div className="col-12 pt-5 pb-5">
                        <div className="container p-0">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="font-bold gold-bg splash-headline d-inline-block p-3">
                                        WE Powers{" "}
                                        <span className="text-red">ME</span>
                                    </div>
                                    <div className="gold-bg p-3 splash-subhead mt-md-3">
                                        <p className="font-bold">
                                            Meet Marketing Exchange.
                                        </p>
                                        <p>
                                            A new centralized platform for all
                                            Marketing knowledge in one smart,
                                            searchable and streamlined system.
                                            WE shape the content, WE reap the
                                            benefits, WE powers ME!
                                        </p>
                                        <p>Scroll down to learn more...</p>
                                    </div>
                                </div>
                                <div className="col-md-4 pt-3 mt-2">
                                    <img
                                        src="/splashpage/animation/battery.gif"
                                        className="img-fluid"
                                    />
                                    <div className="gold-bg d-inline-block splash-launch-date p-5 pt-4 ms-lg-5">
                                        <div className="text-white font-bold">
                                            Charging up to Launch!
                                        </div>
                                        <div className="text-red font-bold">
                                            Coming mid-November 2021
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container splash-content">
                <div className="row">
                    <div className="col-md-9 mt-5 mb-5 splash-subhead">
                        <div className="text-red font-bold pb-3">
                            Features in Action
                        </div>
                        <div>
                            Check out a few powerful new features and learn how
                            WE can contribute to the Marketing Exchange.
                        </div>
                    </div>
                </div>
                <div className="row pb-5">
                    <div className="col-md-5">
                        <video
                            muted={true}
                            autoPlay={true}
                            loop={true}
                            playsInline={true}
                            preload="auto"
                            className="home-animation img-fluid">
                            <source
                                src="/splashpage/animation/homepage.webm"
                                type="video/webm"
                            />
                            <source
                                src="/splashpage/animation/homepage.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className="col-md-7 ps-md-5 pt-4 pt-md-0">
                        <img
                            src="/splashpage/images/icon-home-page.png"
                            className="img-fluid splash-icon"
                        />
                        <div className="font-bold pt-4 pb-3 splash-section-title">
                            Homepage
                        </div>
                        <div className="font-reg">
                            The homepage is our epicenter for sharing McDonald’s
                            marketing knowledge from around the world. Scroll
                            through what’s newest,{" "}
                            <span className="font-bold">what’s trending</span>,
                            focus on a specific topic or use the{" "}
                            <span className="font-bold">advanced search</span>{" "}
                            feature.
                            <br />
                            <br />
                            We make it easy to find what you’re looking for. The
                            site fits your needs and your device - it’s{" "}
                            <span className="font-bold">responsive</span> to
                            both desktop and mobile.{" "}
                            <span className="font-bold">
                                Built to be human-centric
                            </span>
                            , with{" "}
                            <span className="font-bold">modern design</span>,
                            and{" "}
                            <span className="font-bold">
                                easy to navigate, the homepage is the first stop
                                to finding what you’re looking for
                            </span>
                            .
                        </div>
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-md-7 ps-md-5 pt-4 pt-md-0">
                        <img
                            src="/splashpage/images/icon-search.png"
                            className="img-fluid splash-icon"
                        />
                        <div className="font-bold pt-4 pb-3 splash-section-title">
                            Search
                        </div>
                        <div className="font-reg">
                            Search is at the heart of the Marketing Exchange.
                            Advanced search functionality learns from every
                            query to provide more{" "}
                            <span className="font-bold">relevant results</span>{" "}
                            each time you use it.
                            <br />
                            <br />
                            The smart search will auto-fill your search bar when
                            you type, delivering relevant{" "}
                            <span className="font-bold">
                                articles, videos, reports
                            </span>{" "}
                            and <span className="font-bold">documents </span>at{" "}
                            <span className="font-bold">
                                lightning fast speed
                            </span>
                            . For even more targeted content, a library of{" "}
                            <span className="font-bold">tags and filters</span>{" "}
                            is at your fingertips to help you find exactly what
                            you need, and maybe surface something you didn’t
                            know about.
                        </div>
                    </div>
                    <div className="col-md-5">
                        <video
                            muted={true}
                            autoPlay={true}
                            loop={true}
                            playsInline={true}
                            preload="auto"
                            className="home-animation img-fluid">
                            <source
                                src="/splashpage/animation/search.webm"
                                type="video/webm"
                            />
                            <source
                                src="/splashpage/animation/search.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-md-5">
                        <video
                            muted={true}
                            autoPlay={true}
                            loop={true}
                            playsInline={true}
                            preload="auto"
                            className="home-animation img-fluid">
                            <source
                                src="/splashpage/animation/topic.webm"
                                type="video/webm"
                            />
                            <source
                                src="/splashpage/animation/topic.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className="col-md-7 ps-md-5 pt-4 pt-md-0">
                        <img
                            src="/splashpage/images/icon-topic-pages.png"
                            className="img-fluid splash-icon"
                        />
                        <div className="font-bold pt-4 pb-3 splash-section-title">
                            Topic Pages
                        </div>
                        <div className="font-reg">
                            Ready to dive into a specific Marketing topic? Head
                            to the drop down menu on the homepage to find the
                            topic you’re looking for. Topic Pages are organized
                            by themes and include{" "}
                            <span className="font-bold">
                                key contact email addresses
                            </span>{" "}
                            and{" "}
                            <span className="font-bold">
                                business-critical documents
                            </span>{" "}
                            available for download.
                            <br />
                            <br />
                            Each page will show the{" "}
                            <span className="font-bold">
                                latest articles
                            </span>{" "}
                            related to that topic, keeping you in-the-know as a
                            marketing pro. And guess what? No more scratching
                            your head trying to remember{" "}
                            <span className="font-bold">
                                URLs, passwords and folder names
                            </span>
                            . It’s all right there in each Topic Page for ease
                            and speed!
                        </div>
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-md-7 ps-md-5 pt-4 pt-md-0">
                        <img
                            src="/splashpage/images/icon-submit-story.png"
                            className="img-fluid splash-icon"
                        />
                        <div className="font-bold pt-4 pb-3 splash-section-title">
                            Submit an Idea
                        </div>
                        <div className="font-reg">
                            We are always looking for{" "}
                            <span className="font-bold">
                                new market examples, best practices, as well as
                                guidance from Councils and the Center
                            </span>{" "}
                            to share with the marketing community—and now, we
                            have a centralized platform to do just that! Once an
                            idea is shared, the{" "}
                            <span className="font-bold">
                                Knowledge Management team
                            </span>{" "}
                            gets to work{" "}
                            <span className="font-bold">
                                refining, writing, formatting, editing video
                            </span>{" "}
                            and <span className="font-bold">sharing</span> it
                            with the entire marketing community so you don’t
                            have to do the heavy lifting.
                            <br />
                            <br />
                            We’ve made it easy to share! Just click the{" "}
                            <span className="font-bold">
                                ‘Submit an Idea’
                            </span>{" "}
                            button at the bottom of the homepage, answer some
                            brief questions, upload relevant files and hit
                            ‘send’. That’s it! Share your work proudly for our
                            community to{" "}
                            <span className="font-bold">shamelessly steal</span>{" "}
                            across the globe and get the credit you deserve.
                        </div>
                    </div>
                    <div className="col-md-5">
                        <video
                            muted={true}
                            autoPlay={true}
                            loop={true}
                            playsInline={true}
                            preload="auto"
                            className="home-animation img-fluid">
                            <source
                                src="/splashpage/animation/submit.webm"
                                type="video/webm"
                            />
                            <source
                                src="/splashpage/animation/submit.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                </div>
                <div className="row pt-5 pb-5">
                    <div className="col-md-5">
                        <video
                            muted={true}
                            autoPlay={true}
                            loop={true}
                            playsInline={true}
                            preload="auto"
                            className="home-animation img-fluid">
                            <source
                                src="/splashpage/animation/future.webm"
                                type="video/webm"
                            />
                            <source
                                src="/splashpage/animation/future.mp4"
                                type="video/mp4"
                            />
                        </video>
                    </div>
                    <div className="col-md-7 ps-md-5 pt-4 pt-md-0">
                        <img
                            src="/splashpage/images/icon-future.png"
                            className="img-fluid splash-icon"
                        />
                        <div className="font-bold pt-4 pb-3 splash-section-title">
                            Marketing Exchange - The Future
                        </div>
                        <div className="font-reg">
                            This is just the beginning! We have big plans to
                            continue evolving the Marketing Exchange—adding{" "}
                            <span className="font-bold">
                                personalization features
                            </span>{" "}
                            that will shape the content to fit your individual
                            needs. You’ll be able to add{" "}
                            <span className="font-bold">
                                personalized alerts, save articles,{" "}
                            </span>
                            watch{" "}
                            <span className="font-bold">relevant hashtags</span>{" "}
                            and choose content that is{" "}
                            <span className="font-bold">
                                automatically shared to your team
                            </span>{" "}
                            once published on the platform.
                            <br />
                            <br />
                            The future of the Marketing Exchange is bright as we
                            use this platform to continue{" "}
                            <span className="font-bold">
                                cultivating our global marketing community
                            </span>
                            . Your input will help to shape the future of the
                            Marketing Exchange to become an{" "}
                            <span className="font-bold">
                                essential resource
                            </span>{" "}
                            for all McDonald’s marketers.
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 mt-5 mb-5 splash-subhead">
                        <div className="text-red font-bold pb-3">Why ME?</div>
                        <div>
                            We answered some frequently asked questions about
                            the benefits and features of the Marketing Exchange.
                            Click the drop down to learn what this platform is
                            all about.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 splash-content">
                        <div
                            className="accordion accordion-flush"
                            id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id="flush-headingOne">
                                    <button
                                        className="accordion-button font-bold collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseOne"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseOne">
                                        Why was the Marketing Exchange created?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingOne"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        We generate so much marketing knowledge
                                        across the system. And it’s stored in so
                                        many places, often requiring you to
                                        remember URLs, passwords and what is
                                        stored where. In order to drive more
                                        efficient knowledge sharing (and
                                        encourage more shamelessly stolen
                                        work!), we’ve brought it all into a
                                        centralized, easy-to-use, tech-savvy
                                        online destination.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id="flush-headingTwo">
                                    <button
                                        className="accordion-button font-bold collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseTwo">
                                        Who will have access to the Marketing
                                        Exchange?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseTwo"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingTwo"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        All McDonald’s marketers across the
                                        system and their designated agency
                                        partners will have access. The site will
                                        be enabled with single sign-on. If you
                                        have an eID, you should not have access
                                        issues. We are working through ways to
                                        make it easy to gain access if you don’t
                                        have an eID. More to come.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id="flush-headingThree">
                                    <button
                                        className="accordion-button font-bold collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseThree"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseThree">
                                        What will happen to other marketing
                                        knowledge sites like Seeit and Make it
                                        Happy?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingThree"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        Some sites, like Seeit and Make It
                                        Happy, will have their content migrated
                                        to the Marketing Exchange when it
                                        launches, and the sites will become
                                        inactive. Others may store knowledge in
                                        both the existing location and the
                                        Marketing Exchange for a period of time
                                        until we’re all used to using the new
                                        site. And then there are others, like
                                        the Creative Effectiveness Hub and the
                                        Feel-Good Design Hub, that utilize
                                        interfaces that the Marketing Exchange
                                        can’t replicate on day one. We will pull
                                        those sites in eventually, but for now,
                                        existing links will continue to work and
                                        links to them will be available on the
                                        Marketing Exchange homepage.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id="flush-headingFour">
                                    <button
                                        className="accordion-button font-bold collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFour"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFour">
                                        Will the Insights Team continue to use
                                        the KnowledgeHub?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseFour"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingFour"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        Yes! KnowledgeHub will be integrated
                                        into the Marketing Exchange and will
                                        continue to be available to Insights
                                        users, functioning as it always has.
                                        Insights users can access it directly
                                        via a saved favorite URL in your
                                        browser, or via a link once inside the
                                        Marketing Exchange. The new development
                                        is that KnowledgeHub will become a
                                        repository for documents from ALL
                                        marketing functions, not just Insights.
                                        However, there’s no need to learn a new
                                        platform. The Marketing Exchange will
                                        seamlessly tap into those documents via
                                        the search bar. Insights users can
                                        continue to use the full set of features
                                        within KnowledgeHub. Or if it’s easier,
                                        just type what you’re looking for into
                                        the search bar on the Marketing
                                        Exchange, and your document will be
                                        served up without needing to enter
                                        KnowledgeHub.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id="flush-headingFive">
                                    <button
                                        className="accordion-button font-bold collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseFive"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseFive">
                                        In what ways will the Marketing Exchange
                                        be an improvement over other sites we
                                        use today?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseFive"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingFive"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        There will be many improvements. First,
                                        all marketing knowledge will be
                                        centralized in one place, making it easy
                                        and efficient to find when you need it.
                                        Enabling that will be search technology
                                        that is more advanced compared to what
                                        you currently have on other internal
                                        sites. Search is powered by auto-tagging
                                        and machine learning. So it gets smarter
                                        the more we use it, which means it gets
                                        better at delivering the most relevant
                                        search results. No need to click through
                                        various pages and folders to find what
                                        you need. Just search! Think of it the
                                        same way you would if you opened Google
                                        and just typed in the words that came to
                                        mind.
                                        <br />
                                        <br />
                                        Another great feature will be Topic
                                        Pages for each marketing function if you
                                        need to hone in on a particular subject.
                                        All will follow the same template,
                                        making it easy to quickly find and grab
                                        what you need, whether it’s a contact
                                        name/email or that one document you
                                        can’t seem to track down.
                                        <br />
                                        <br />
                                        As new phases of features are added
                                        going forward, you will be able to
                                        create your own profile and search for
                                        your colleagues. You’ll be able to tell
                                        the system what types of information
                                        you’re most interested in and will be
                                        alerted when it’s added. Articles and
                                        quotes can be saved in personal folders.
                                        And we’ll be taking cues from you for
                                        how to add collaboration features that
                                        make it easier to learn from each other.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id="flush-headingSix">
                                    <button
                                        className="accordion-button font-bold collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSix"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSix">
                                        Will pages on @mcd continue to hold
                                        marketing documents, or will they move
                                        to the Marketing Exchange?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseSix"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingSix"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        We will leave files on @mcd until we’re
                                        sure everything is working correctly.
                                        However, all of those files will be
                                        duplicated on the appropriate Topic Page
                                        on the Marketing Exchange. Eventually,
                                        files will be removed from those @mcd
                                        pages and we will take them down
                                        completely.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id="flush-headingSeven">
                                    <button
                                        className="accordion-button font-bold collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseSeven"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseSeven">
                                        Will there still be a weekly Global
                                        Marketing Newsletter?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseSeven"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingSeven"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        Yes! In Case You Missed It was created
                                        so that Global Marketing could have a
                                        single voice to provide critical and
                                        timely direction to markets during the
                                        height of COVID-19. The spirit of that
                                        “single voice” will remain intact when
                                        the newsletter is re-branded and
                                        re-designed as the Marketing Exchange
                                        Spotlight. We will remain committed to
                                        publishing and amplifying marketing
                                        content only when it provides value and
                                        is actionable – never to just report the
                                        news or to post “fluff,” simply to have
                                        a reason to send it out. Therefore, the
                                        newsletter may not always be distributed
                                        every week, but we will aim to have it
                                        in your inbox around the world each
                                        Friday morning.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2
                                    className="accordion-header"
                                    id="flush-headingEight">
                                    <button
                                        className="accordion-button font-bold collapsed"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#flush-collapseEight"
                                        aria-expanded="false"
                                        aria-controls="flush-collapseEight">
                                        My market has some great work to share
                                        with the system – how do I get it
                                        featured on the Marketing Exchange?
                                    </button>
                                </h2>
                                <div
                                    id="flush-collapseEight"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="flush-headingEight"
                                    data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        We’ve made it easier than ever. At the
                                        bottom of the Marketing Exchange
                                        homepage, you will find a button that
                                        says “Submit an Idea.” Clicking there
                                        will take you to an online form that
                                        includes about 10 questions. No need to
                                        spend a lot of time writing a lengthy
                                        description – short bullets are fine.
                                        Drag in files, images and/or videos that
                                        provide the details (don’t create
                                        anything new!), and our content team
                                        will go to work to identify the best
                                        approach to showcase your work in a way
                                        that is actionable to other markets. We
                                        may come back to you with a short list
                                        of clarifying questions over email or a
                                        quick call. It’s important that we dig
                                        deep – we never just report what you did
                                        and your results. We will want to
                                        highlight the business context, the
                                        problems you solved, the deeper
                                        insights, your thought processes and
                                        what you learned. Without all of that,
                                        it would be difficult for other markets
                                        to shamelessly steal from your great
                                        work. And that’s our ultimate goal!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid splash-bg mt-5">
                <div className="row">
                    <div className="col-12 pt-5 pb-5">
                        <div className="container ps-0 pe-0 pb-5 mb-5">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="gold-bg p-4 splash-subhead mt-md-5">
                                        <p className="font-bold">
                                            Is there something we haven’t
                                            covered?
                                        </p>
                                        <p className="font-bold">
                                            Please{" "}
                                            <a
                                                href="mailto:rick.gray@us.mcd.com"
                                                target="_blank">
                                                click here
                                            </a>{" "}
                                            to submit your questions to the
                                            Global Marketing team.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SplashPage;
