import React, { useState, useEffect } from "react";
import styles from "styles/nav/Nav.module.scss";
import { log } from "console";
import Hamburger from "hamburger-react";
import axios from "axios";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import MobileMenu from "./MobileMenu";

// Next
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

// State
import {
  navbarColorState,
  showTopSearchState,
  topicListState,
  loginState,
  isFGDUserState,
  currentUserState,
  menuState,
  fgdMenuState,
  hideMenuTooltipState
} from "state/atoms";
// APIs
import {
  fetchData,
  sortTopicsFromApi,
  processS3Image,
  fetchMenu,
  logoutFGD
} from "helpers/functions";

import { dataTopics } from "helpers/sampleData";

// Interfaces
import { IfaceTopicApi, IfaceCurrentUser, IfaceMenuApi } from "helpers/interfaces";

// Images
// import Logo from "images/Logo-outlines-new.svg";
// import LogoMobile from "images/Logo-outlines-new-icon.svg";
import Logo from "images/Marketing_Exchange_Logo_NoE-02.svg";
import FGDLogo from "images/fgd-logo-new.svg";
// import LogoMobile from "images/Marketing_Exchange_Logo_Mobile_NoE-04.svg";
// import LogoMobileAlt from "images/logo-mobile-alt.svg";
// import IconDropdown from "images/icon-dropdown.svg";
import IconBookmarks from "images/icon-bookmarks.svg";
import SearchIcon from "images/Search.svg";
import BackIcon from "images/ui-back-icon.svg";

// Components
import Search from "./Search";
// import AlertToggle from "./AlertToggle"; // Not in MVP
import AdminToggle from "./AdminToggle";
import TopicsToggle from "./TopicsToggle";
import MenuToggle from "./MenuToggle";
// import NotificationsToggle from "./NotificationsToggle";
import NotfiBell from "./MagicBell";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";

// Recoil Setup

// For testing
// TODO: Once auth is enabled, make this only available for admins

interface Notifcations {
  image_src: string;
  title: string;
  description: string;
  date: string;
  hasRead: boolean;
}

let notifcations: Notifcations[] = [
  {
    image_src: '',
    title: 'Brand new Marketing Exchange custom profiles are here',
    description: '',
    date: '29 mins ago',
    hasRead: false
  },
  {
    image_src: '',
    title: 'Take a tour of the features included in the new Marketing Exchange.',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum quis enim, ut at tristique. Semper sed purus amet.',
    date: '29 mins ago',
    hasRead: false
  },
  {
    image_src: '',
    title: 'Have a great idea? Submit your own marketing case study. ',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim nibh quis at blandit rhoncus suspendisse. Vitae sed amet.',
    date: '29 mins ago',
    hasRead: true
  },
  {
    image_src: '',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    description: '',
    date: '29 mins ago',
    hasRead: false
  },
]

type Props = {
  color?: string;
  // profile?: {
  //     image: {
  //         src: string;
  //         alt: string;
  //     };
  // };
};

const backendUrl = process.env.NEXT_PUBLIC_API_URL;
const publicEnv = process.env.NEXT_PUBLIC_ENV;
const publicURL = process.env.NEXT_PUBLIC_FE_URL;
const logoutLink = `${backendUrl}/auth/logout`.replace("//auth", "/auth");

const Nav = () => {
  // Use state for current window width
  const [width, setWidth] = useState(1400); // set as desktop as the default to prevent FOUC
  const [isSticky, setIsSticky] = useState(true);
  const [isSolid, setIsSolid] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [lastDown, setLastDown] = useState(0);
  const [lastUp, setLastUp] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [showTopicDropdown, setShowTopicDropdown] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showNotifsDropdown, setShowNotifsDropdown] = useState(false);
  const [showAdminDropdown, setShowAdminDropdown] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true); // TODO: remove or set to false — for UI testing only
  const [hideTooltip, setHideTooltip] = useRecoilState(hideMenuTooltipState);
  // New Nav Color with Recoil
  const navbarColor = useRecoilValue(navbarColorState);
  const showTopSearch = useRecoilValue(showTopSearchState);
  const thisUser: IfaceCurrentUser = useRecoilValue(currentUserState);

  const removeAdminUI = () => {
    setIsAdmin(false);
  };

  // When component renders or window is resized, grab new window width
  useEffect(() => {
    setWidth(window.innerWidth);
    const onResize = () => {
      setWidth(window.innerWidth);
      // console.log(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
      // console.log("resize unmounted");
    };
  }, []); // Only add to the stack when component is first mounted

  // Set up topics and state

  const [topics, setTopics] = useRecoilState(topicListState);

  const getData = async () => {
    const topicsApi: IfaceTopicApi = await fetchData(`menu/topics`);
    setTopics(topicsApi);
    // setTopics(dataTopics);
  };

  useEffect(() => {
    getData();
  }, []);

  // Set up menu and state
  const [menu, setMenu] = useRecoilState(menuState);
  const [fgdMenu, setFGDMenu] = useRecoilState(fgdMenuState);

  const getMenus = async () => {
    const url = window.location.href;
    const data: IfaceMenuApi = await fetchMenu('main-menu');
    console.log('menu data', data);
    if (!data.error) {
      setMenu(data);
    }
    const fgdData: IfaceMenuApi = await fetchMenu('fgd-menu');
    if (!fgdData.error) {
      setFGDMenu(fgdData);
    }
  };

  useEffect(() => {
    getMenus();
  }, []);

  // On Scroll, update the Set Scroll Pos
  useEffect(() => {
    const onScroll = (e: any) => {
      const scrollStuff = () => {
        const newPos = e.target.documentElement.scrollTop;
        // logic for updating last down and last up based on scroll direction
        if (newPos > scrollPos) {
          // When Scrolling Down
          setLastDown(newPos);
        } else {
          // When Scrolling Up
          setLastUp(newPos);
        }
        setScrollPos(newPos); // update scrollPos
      };
      window.requestAnimationFrame(scrollStuff); // for performance
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollPos]); // TODO: can this be refactored?

  // Scrollbar conditionals
  useEffect(() => {
    let newPos = scrollPos;
    const threshold = 50; // buffer for nav to appear
    // logic for determining if we should toggle the sticky
    if (newPos < 5) {
      setIsSticky(true);
    } else if (newPos > lastUp + threshold && isSticky && !isOpen) {
      setIsSticky(false);
      setIsOpen(false); // close mobile nav
      setShowTopicDropdown(false); // close topic dropdown
    } else if (newPos < lastDown - threshold && !isSticky) {
      setIsSticky(true);
    }
    setSolid(newPos); // logic to see if it should be solid BG
  }, [scrollPos, isOpen, lastUp, lastDown]);

  const setSolid = (scrollPos: number) => {
    const threshold = 300;
    if (scrollPos > threshold && !isSolid) {
      setIsSolid(true);
    } else if (scrollPos <= threshold && isSolid) {
      setIsSolid(false);
    }
  };

  // Hamburger
  let classes = `topnav ${styles.topnav}`;
  let searchColor = navbarColor;

  if (navbarColor === "dark" && !isSolid) classes += ` dark ${styles.dark}`;

  if (isSolid === true || isOpen === true) {
    classes += ` ${styles.isSolid}`;
    searchColor = "";
  }
  if (isOpen === true) {
    classes += ` ${styles.noShadow}`;
    searchColor = "";
  }

  if (isSticky) {
    classes += ` ${styles.sticky}`;
  }

  // Handle body scroll lock when nav is opened
  useEffect(() => {
    const scrollableNav = document.getElementById(
      "mobileNav"
    ) as HTMLElement;
    isOpen
      ? disableBodyScroll(scrollableNav)
      : enableBodyScroll(scrollableNav);
  }, [isOpen]);

  const toggleIsOpen = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  // State management for topic dropdown (desktop)
  const toggleTopicDropdown = () => {
    showTopicDropdown
      ? setShowTopicDropdown(false)
      : setShowTopicDropdown(true);
  };

  const toggleMenu = () => {
    showMenu ? setShowMenu(false) : setShowMenu(true);
	const body = document.getElementsByClassName('body');
	showMenu ? body[0].classList.remove('no-scroll') : body[0].classList.add('no-scroll');

    if (!hideTooltip) {
      setHideTooltip(true);
    }
  };
  // End state management for topic dropdown (desktop)

  // State management for admin dropdown (desktop)
  const toggleAdminDropdown = () => {
    showAdminDropdown
      ? setShowAdminDropdown(false)
      : setShowAdminDropdown(true);
  };
  // End state management for admin dropdown (desktop)

  // State management for notifications dropdown (desktop)
  const toggleNotifsDropdown = () => {
    showNotifsDropdown
      ? setShowNotifsDropdown(false)
      : setShowNotifsDropdown(true);
  };
  // End state management for notifications dropdown (desktop)

  // Feel Good Design Homepage
  const [isFGDUser, setFGDUser] = useRecoilState(isFGDUserState);
  const [isFGDLogin, setIsFGDLogin] = useState<boolean>(false);
  const [useFGDLogo, setUseFGDLogo] = useState<boolean>(false);
  const [showBackBtn, setShowBackBtn] = useState<boolean>(false);

  // Check for FGD pages for logo
  useEffect(() => {
    if (window) {
      const pathname: any = window.location.pathname;
      if ((pathname.includes('/design') && !pathname.includes('search/design')) || pathname.includes('/guidelines/') || pathname.includes('/downloads/') || pathname.includes('/gallery') || pathname.includes('/getting-started')) {
        setUseFGDLogo(true);
		setShowBackBtn(true);
      }
    }
  }, []);

  // Reset menu state on URL change
  const router = useRouter();
  const [pathname, setPathname] = useState<any>(null);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false);
      setShowTopicDropdown(false);
    };
    router.events.on("routeChangeStart", handleRouteChange);

    if (window.location && (window.location.pathname.includes('/design/login') || window.location.pathname.includes('/reset-password'))) {
      setIsFGDLogin(true);
    }
  }, []);

  useEffect(() => {
    if(router && router.pathname) {
      setPathname(router.pathname);
    }
  }, [router]);

  // FGD Logout
  const handleFGDLogout = async (e: any) => {
    const response: any = await logoutFGD();
    if (response.loaded && !response.error) {
      let publicUrl: string = publicURL ? publicURL : 'https://frontend.kmp.test';
      if (publicEnv === 'Development') {
        publicUrl = 'https://frontenddev.kmpadmin81.cliquedomains.com/';
      } else if (publicEnv === 'Staging') {
        publicUrl = 'https://staging.me.mcd.com';
      } else if (publicEnv === 'Production') {
        publicUrl = 'https://me.mcd.com';
      }
      router.push(`${publicUrl}/design/login`);
    }
  }

  if (isFGDLogin) {
    return <></>;
  } else {
    return (
      <>
        <nav
          id="topnav"
          className={classes}
          data-sticky={isSticky ? true : false}>
          <div className="wrapper">
            <div className={styles.grid}>
              <div className={styles.left}>
                <div className={styles.logoContainer}>
                  <Link href={(useFGDLogo || isFGDUser) ? '/design' : '/'}>
                    <a aria-label="The Market Place - Click to go home">
                      {useFGDLogo ? (
                        <img src={'/images/fgd-logo.png'} alt={'feel-good design hub'} className={styles.fgdLogo} />
                      ) : (
                        <Logo />
                      )}
                    </a>
                  </Link>
                </div>
                {!isFGDUser && width > 1100 && showBackBtn && (
                  <div className={styles.backButtonContainer}>
                    <a href={'/'} className={styles.backButton}>
                      <span>Back to Homepage</span>
                      <BackIcon />
                    </a>
                    <div className={styles.backTooltip}>
                      <div className={styles.backTooltipInner}>
                        <p>Click here for the Marketing Exchange home page</p>
                      </div>
                    </div>
                  </div>
                )}
                {width > 1100 && (
                  <ul className={styles.navlinks}>
                    <li className={styles.menuToggle}>
                      {((!menu.error && menu.loaded) || (!fgdMenu.error && fgdMenu.loaded)) && (
                        <MenuToggle
                          showMenu={showMenu}
                          toggleMenu={toggleMenu}
                          menu={menu}
                          fgdMenu={fgdMenu}
                          isFGDUser={isFGDUser}
                        />
                      )}
                    </li>
                  </ul>
                )}
              </div>
              <div className="search">
                {width > 1100 && !router.pathname.includes('/search') && (
                  <Search color={searchColor} />
                )}
              </div>
              {isFGDUser ? (
                <div className={styles.buttonsContainer}>
                  <Link href={'/getting-started'}>
                    <a className={`btn secondary ${styles.fgdBtn}`}>Getting Started</a>
                  </Link>
                  <button className={`${styles.logoutBtn}`} onClick={handleFGDLogout}>Logout</button>
                </div>
              ) : (
                <>
                  {width > 1100 && (
                    // Desktop Navigation
                    <ul className={`${styles.navlinks} ${styles.rightlinks}`}>
                      {/* <li className={styles.navlink}>
                                      <Link href="/pagelist">👩‍💻</Link>
                                  </li> */}
                      <li id={'magicbell-item'} className={`notfications-link ${styles.notficationsLink}`}>
                        <NotfiBell />
                      </li>
                      <li id={'bookmark-item'} className={styles.bookmarkLink}>
                        <Link href="/bookmarks">
                          <button>
                            <IconBookmarks />
                          </button>
                        </Link>
                      </li>
                      <li id={'profile-item'} className={styles.profileLinkGroup}>
                        <Link href="/profile">
                          <a id={'profile-link'} className={styles.profileToggle}>
                            <Image
                              unoptimized={true}
                              src={
                                thisUser
                                  ? processS3Image(
                                    thisUser.avatar
                                  ) ||
                                  "/images/default-user.svg"
                                  : "/images/default-user.svg"
                              }
                              alt={''}
                              width={55}
                              height={55}
                            />
                          </a>
                        </Link>
                        <AdminToggle
                          toggleAdminDropdown={
                            toggleAdminDropdown
                          }
                          showAdminDropdown={
                            showAdminDropdown
                          }
                          removeAdminUI={removeAdminUI}
                        />
                      </li>
                    </ul>
                  )}
                </>
              )}
              {width <= 1100 && (
                // Mobile Nav Toggle
                <ul className={styles.navlinks}>
                  {!isFGDUser && pathname && pathname !== '/' && (
                    <div className={styles.backButtonContainer}>
                      <a href={'/'} className={styles.backButton}>
                        <span>Back to Homepage</span>
                        <BackIcon />
                      </a>
                      <div className={styles.backTooltip}>
                        <div className={styles.backTooltipInner}>
                          <p>Click here for the Marketing Exchange home page</p>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isFGDUser && (
                    <li className={`notfications-link ${isOpen && 'menu-open'}`}>
                      <NotfiBell />
                    </li>
                  )}
                  <li>
                    <button onClick={toggleIsOpen}>
                      <Hamburger
                        toggled={isOpen}
                      // toggle={toggleIsOpen}
                      />
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
        {width <= 1100 && (
          // Mobile Nav
          <div className={styles.mobileParent}>
            <div
              id="mobileProfile"
              className={styles.mobileProfile}
              data-active={isOpen ? "true" : "false"}
              aria-hidden={isOpen ? "false" : "true"}
              tabIndex={isOpen ? 0 : -1}>
              <div className="wrapper">
                <div className="picture">
                  <Image
                    unoptimized={true}
                    src={
                      thisUser !== null
                        ? processS3Image(thisUser.avatar) ||
                        "/images/default-user.svg"
                        : "/images/default-user.svg"
                    }
                    alt={''}
                    width={55}
                    height={55}
                  />
                </div>
                <div className={styles.info}>
                  <span className="name">
                    {thisUser.name || "User"}
                  </span>
                  <span className="profile">
                    <Link href="/profile">
                      <a>View Profile</a>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <nav
              id="mobileNav"
              className={styles.mobileNav}
              data-active={isOpen ? "true" : "false"}
              aria-hidden={isOpen ? "false" : "true"}
              tabIndex={isOpen ? 0 : -1}>
              <div className="content wrapper">
                {!router.pathname.includes('/search') && (
                  <div className="search-mobile">
                    <Search color={searchColor} />
                  </div>
                )}
                {!isFGDUser && (
                  <ul className="primary">
                    <li>
                      <Link href="/bookmarks">
                        <a>Bookmarks</a>
                      </Link>
                    </li>
                    <li>
                      <Link href="/articles/all-articles">
                        <a>All Articles</a>
                      </Link>
                    </li>
                  </ul>
                )}
                {isFGDUser && (
                  <ul className="primary">
                    <li>
                      <button className={``} onClick={handleFGDLogout}>Logout</button>
                    </li>
                    <li>
                      <Link href="/getting-started">
                        <a>Getting Started</a>
                      </Link>
                    </li>
                  </ul>
                )}
                {!menu.error && menu.loaded && (
                  <MobileMenu
                    menu={menu}
                    fgdMenu={fgdMenu}
                    isFGDUser={isFGDUser}
                  />
                )}
              </div>
            </nav>
          </div>
        )}
      </>
    );
  }
};

export default Nav;
