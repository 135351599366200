import styles from "styles/components/LoadingScreen.module.scss";

interface Props {
    loaded: boolean;
}

const LoadingScreen = ({ loaded }: Props) => {
    return (
        <div
            className={`${styles.loadingScreen} ${
                loaded ? styles.loaded : styles.unloaded
            }`}>
            &nbsp;
        </div>
    );
};

export default LoadingScreen;
