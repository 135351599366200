import React, { useState } from "react";
import Link from "next/link";
// import axios from "axios";
import { useEffect } from "react";
import { fetchData, checkIfEmpty } from "helpers/functions";
import { GetServerSideProps } from "next";
import { useRecoilState } from "recoil";
import { footerMenuState, isFGDUserState } from "state/atoms";
import { useRouter } from "next/router";
// Interfaces
import { ApiResponse } from "helpers/interfaces";

const Footer = () => {
  // Let's put the footer menu items in global state so we don't refetch 
  const [footerMenu, setFooterMenu] = useRecoilState(footerMenuState);

  // Function to grab footer items client side
  async function fetchFooterItems() {
    const menuItems = await fetchData("menu/footer");
    if (menuItems.error === false) {
      setFooterMenu(menuItems);
    } else {
      // console.log("footer error", menuItems);
      // TODO: remove this hardcoded or solidify
      setFooterMenu({
        loaded: true,
        error: false,
        res: {
          data: [
            {
              title: "InsightsHub",
              url: "https://mcdonalds.stravito.com/app/",
              target: "_blank",
            },
            {
              title: "Burgey",
              url: "https://burgey.mcd.com/",
              target: "_blank",
            },
            {
              title: "FAQ",
              url: "/faq",
              target: "_blank",
            },
          ],
        },
      });
    }
    return menuItems;
  }

  // Logic to grab the footer menu items if they aren't already loaded into state
  // This way, we only fetch one API call per visit to site.
  // TODO: pass footer menu state to cookie to expire after 5-10 minutes?
  useEffect(() => {
    if (!footerMenu.loaded) {
      fetchFooterItems();
    }
  }, [footerMenu]);

  const date: any = new Date();

  // Feel Good Design Homepage
  const [isFGDUser, setFGDUser] = useRecoilState(isFGDUserState);

  if (isFGDUser) {
    return (
      <footer className={'fgd-footer'}>
        <div className="wrapper">
          <div className="wrapper">
            <div className="footer-content">
              <div className="links">
                
                <ul>
                <li key={'contact-link'}>
                        <a href={'https://www.mcdonalds.com/us/en-us/contact-us.html'} target={'_blank'}>Contact Us</a>
                      </li>
                </ul>
              </div>
              <div className="cta"></div>
              <div className="copyright">
                <ul>
                  <li>&copy;Copyright&nbsp;{date.getFullYear()}</li>
                  <li>
                    <a href={'/privacy'}>View the Marketing Exchange Privacy Statement</a>.
                    <br />
                    <br />
                    <a href={'/terms'}>View Terms & Conditions</a>. PLEASE CAREFULLY READ AND UNDERSTAND THESE TERMS OF USE. THEY CONTAIN LIMITATIONS ON McDONALD’S LIABILITY AND OTHER PROVISIONS THAT AFFECT YOUR LEGAL RIGHTS.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  } else if (footerMenu.loaded) {
    return (
      <footer>
        <div className="wrapper">
          <div className="wrapper">
            <div className="footer-content">
              <div className="links">
                <p>
                  Click the links below to be taken directly
                  to these other McDonald’s resources.
                </p>
                <ul>
                  {footerMenu.loaded &&
                    footerMenu.res.data.map((item) => (
                      <li key={item.title}>
                        <a
                          href={item.url}
                          target={item.target}>
                          {item.title}
                        </a>
                      </li>
                    ))}
                  {/* {!footerMenu.loaded && (
                                        <li>Footer menu not loaded</li>
                                    )} */}
                </ul>
              </div>
              <div className="cta">
                <p className="small">
                  We all benefit when we share learning. If you’ve got a marketing example that has
                  taken place in your market and is something you are able to share with the global
                  marketing community, please share the details in this short form. We’ll be in touch
                  with you to see how we can turn your experience into a great article on the
                  Marketing Exchange.{" "}
                </p>
                <Link href="/case-study">
                  <a className="btn secondary narrow">
                    Submit Your Marketing Case Study
                  </a>
                </Link>
              </div>
              <div className="copyright">
                <ul>
                  <li>&copy;Copyright&nbsp;{date.getFullYear()}</li>
                  <li>
                    <a href={'/privacy'}>View the Marketing Exchange Privacy Statement</a>.
                    <br />
                    <br />
                    <a href={'/terms'}>View Terms & Conditions</a>. PLEASE CAREFULLY READ AND UNDERSTAND THESE TERMS OF USE. THEY CONTAIN LIMITATIONS ON McDONALD’S LIABILITY AND OTHER PROVISIONS THAT AFFECT YOUR LEGAL RIGHTS.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  } else {
    return <footer></footer>;
  }
};

export default Footer;
