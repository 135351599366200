import React, { useState, useEffect } from "react";
import Hamburger from "hamburger-react";
import { useRouter } from "next/router";

// Helpers
import { useRecoilState } from "recoil";
import { fetchMenu } from "helpers/functions";
import { IfaceMenuApi } from "helpers/interfaces";

// State
import { coreMenuState } from "state/atoms";

// Next
import Link from "next/link";

// Components
import CoreMenuSearch from "./CoreMenuSearch";

// Styles
import styles from "styles/nav/CoreMenuNav.module.scss";

// Images
import Logo from "images/core-menu-logo.svg";
import BackIcon from "images/ui-back-icon.svg";
import NavArrow from "images/ui-core-menu-arrow.svg";
import Search from "images/Search.svg";

const NavItem = (props: any) => {
    const title: any = props.title;
    const link: any = props.link;
    const submenu: any = props.submenu ?? null;
    const width: any = props.width;
    const [showSubmenu, setShowSubmenu] = useState<boolean>(false);

    return (
        <>
            <div className={styles.navItem}>
				{link && <a href={link}>{title}</a>}
                {submenu && (
                    <button className={styles.subnavToggle} aria-label={'toggle dropdown menu'} onClick={() => {
                        setShowSubmenu(!showSubmenu);
                    }}>
                        {title} <NavArrow />
                    </button>
                )}
            </div>
            {submenu && (showSubmenu ) && (
                <ul className={styles.submenu}>
                    {submenu.length > 0 && submenu.map((item: any) => {
                        return (
                            <li>
                                <a href={item.url}>{item.name}</a>
                            </li>
                        )
                    })}
                </ul>
            )}
        </>
    )
}

const MainMenu = (props: any) => {
    const width: any = props.width;
    const pathname: any = props.pathname;
	const showSearch: any = props.showSearchBox;
	const menu: any = props.menu.res.data;

	const [showSearchBox, setShowSearchBox] = useState<boolean>(false);
	const toggleSearch = () => {
		setShowSearchBox(!showSearchBox);
	}

    return (
		<>
		<ul>
			{menu.map((item: any, index: any) => {
				if (item.children.length > 0) {
					return (
						<li>
							<NavItem
								title={item.name}
								link={item.url}
								width={width}
								submenu={item.children}
							/>
						</li>
					)
				}
				return (
					<li>
						<NavItem
							title={item.name}
							link={item.url}
							width={width}
						/>
					</li>
				)
			})}
		</ul>
		<button className={styles.searchToggle} onClick={toggleSearch}><Search /> <span>Search</span></button>
			{showSearchBox &&
				<CoreMenuSearch setShowSearchBox={setShowSearchBox} />
			}
		</>
    )
}

const CoreMenuNav = () => {
    const [isSticky, setIsSticky] = useState(true);
    const [isSolid, setIsSolid] = useState(false);
    const [scrollPos, setScrollPos] = useState(0);
    const [lastDown, setLastDown] = useState(0);
    const [lastUp, setLastUp] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [showTopicDropdown, setShowTopicDropdown] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
	const [showSearchBox, setShowSearchBox] = useState<boolean>(true);

    const setSolid = (scrollPos: number) => {
        const threshold = 300;
        if (scrollPos > threshold && !isSolid) {
            setIsSolid(true);
        } else if (scrollPos <= threshold && isSolid) {
            setIsSolid(false);
        }
    };

	const [menu, setMenu] = useRecoilState(coreMenuState);
  
	const getMenus = async () => {
	  const data: IfaceMenuApi = await fetchMenu('cmm-menu');
	  console.log('menu data', data);
	  if (!data.error) {
		setMenu(data);
	  }
	};

	useEffect(() => {
		getMenus();
	}, [])

    // Scrollbar conditionals
    useEffect(() => {
        let newPos = scrollPos;
        const threshold = 50; // buffer for nav to appear
        // logic for determining if we should toggle the sticky
        if (newPos < 5) {
            setIsSticky(true);
        } else if (newPos > lastUp + threshold && isSticky && !isOpen) {
            setIsSticky(false);
            setIsOpen(false); // close mobile nav
            setShowTopicDropdown(false); // close topic dropdown
        } else if (newPos < lastDown - threshold && !isSticky) {
            setIsSticky(true);
        }
        setSolid(newPos); // logic to see if it should be solid BG
    }, [scrollPos, isOpen, lastUp, lastDown]);

    let classes = `topnav ${styles.topnav}`;

    if (isSticky) {
        classes += ` ${styles.sticky}`;
    }

    // Reponsive
    const [width, setWidth] = useState(1400);
    useEffect(() => {
        setWidth(window.innerWidth);
        const onResize = () => {
            setWidth(window.innerWidth);
            if (window.innerWidth >= 1224 && showMobileMenu) {
                setShowMobileMenu(false);
            }
            // console.log(window.innerWidth);
        };
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
            // console.log("resize unmounted");
        };
    }, []); // Only add to the stack when component is first mounted

    // Reset menu state on URL change
    const router = useRouter();
    const [pathname, setPathname] = useState<any>(null);

    useEffect(() => {
        if(router && router.pathname) {
          setPathname(router.pathname);
        }
      }, [router]);

	  console.log('menu', menu);

    return (
        <>
            <nav id={'topnav'} className={classes} data-sticky={isSticky ? true : false}>
                <div className={`${styles.wrapper} wrapper`}>
                    <div className={`${styles.grid} grid`}>
						<div className={styles.navLeft}>
							<div className={styles.logo}>
								<Link href={'/globalcoremenu'}>
									<a aria-label="Global Core Menu Standards and Principals">
										<Logo />
									</a>
								</Link>
							</div>
							<div className={styles.backButtonContainer}>
								<a href={'/'} className={styles.backButton}>
									<span>Back to Homepage</span>
									<BackIcon />
								</a>
								<div className={styles.backTooltip}>
									<div className={styles.backTooltipInner}>
										<p>Click here for the Marketing Exchange home page</p>
									</div>
								</div>
							</div>
						</div>
                        {width >= 1224 ? (
                            <MainMenu
                                width={width}
                                pathname={pathname}
								showSearchBox={showSearchBox}
								menu={menu}
                            />
                        ) : (
                            <button className={styles.hamburgerBtn} aria-label={'toggle hamburger menu'} onClick={() => {
                                setShowMobileMenu(!showMobileMenu);
                            }}>
                                <Hamburger />
                            </button>
                        )}
                    </div>
                </div>
            </nav>
            {width < 1224 && (
                <nav className={styles.mobileMenu} data-active={showMobileMenu ? "true" : "false"} aria-hidden={showMobileMenu ? "false" : "true"}>
                    <div className={`${styles.wrapper} wrapper`}>
                        <MainMenu
                            width={width}
                            pathname={pathname}
							menu={menu}
                        />
                    </div>
                </nav>
            )}
        </>
    )
}

export default CoreMenuNav;