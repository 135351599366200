import React, { useState } from "react";
import MagicBell, { useNotification } from '@magicbell/magicbell-react';
import { HTMLReactParserOptions } from "html-react-parser";
import Gallery from "components/article/Gallery";
import parse, { DOMNode, domToReact } from "html-react-parser";
import styles from "styles/components/NotifsToggle.module.scss";
import IconDots from "images/icon-dots.svg";

const options: HTMLReactParserOptions = {
  replace: (
    { attribs, children }: any // Touchup this 'any' type if can find supporting documentation in html-react-parser
  ) =>
    attribs &&
    attribs.class === "ck-gallery-content" && (
      <Gallery children={children} />
    ),
};

function timeSince(date: any) {

  let currentDate: any = new Date();

  var seconds = Math.floor((currentDate - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

const CustomNotification = ({ notification }: any) => {
  const state = useNotification(notification);
  const handleClick = () => state.markAsRead();
  // const notifDate = state && state.sentAt ? state.sentAt['$d'] : new Date();
  // const dateDiff = timeSince(notifDate) : '';
  const notifId = state.id;
  const [showOptions, setShowOptions] = useState<boolean>(false);

  document.body.addEventListener('click', (e) => {
    const container = document.getElementById(notifId);
    if (container !== null && (container !== e.target && !container.contains(e.target as Node))) {
      // updateActivePrefs(container);
      setShowOptions(false);
    }
  }, true);

  const url: any = state.actionUrl ? state.actionUrl : '#';

  return (
    <div className={`${styles.notifItem}`}>
      <div className={styles.textContainer}>
        <a href={url} onClick={handleClick} className={'notification-item'}>{state.title}</a>
        <p className={styles.title}>{state.title}</p>
        {state.content && <div className={styles.content}>{parse(state.content, options)}</div>}
      </div>
      <div id={notifId} className={`status-container ${styles.statusContainer}`}>
        <button className={`${styles.optionsToggle} ${!state.isRead && styles.unread} ${showOptions && 'open'}`} onClick={() => {
          showOptions ? setShowOptions(false) : setShowOptions(true);
        }}>
          <span className={styles.text}>Toggle Notification Options</span>
          <IconDots />
        </button>
        {showOptions && (
          <ul className={styles.notifOptions}>
            <li>
              <button onClick={() => {
                setShowOptions(false);
                state.isRead ? state.markAsUnread() : state.markAsRead();
              }}>Mark as {state.isRead ? 'Unread' : 'Read'}</button>
            </li>
            <li>
              <button onClick={() => {
                setShowOptions(false);
                state.delete();
              }}>Delete</button>
            </li>
          </ul>
        )}
      </div>
    </div>
  )
}

export default CustomNotification;
