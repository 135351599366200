import React, { useRef, useEffect, useState } from "react";
import styles from "styles/components/AdminToggle.module.scss";
import Image from "next/image";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginState, currentUserState } from "state/atoms";
import Link from "next/link";
import { useRouter } from "next/router";
import {IfaceCurrentUser} from "../../helpers/interfaces";
// SVGs
import IconDropdown from "images/icon-dropdown.svg";

const linkToCMS = process.env.NEXT_PUBLIC_API_URL;

type Props = {
    toggleAdminDropdown: () => void;
    removeAdminUI: () => void;
    showAdminDropdown: boolean;
};

const backendUrl = process.env.NEXT_PUBLIC_API_URL;
const logoutLink = `${backendUrl}/auth/logout`.replace("//auth", "/auth");

declare const window: any;

const AdminToggle = ({
    showAdminDropdown,
    toggleAdminDropdown,
    removeAdminUI,
}: Props) => {
    // // for Logout button
    // const [isLoggedin, setIsLoggedin] = useRecoilState(loginState);
    // const router = useRouter();

    // const logMeOut = (e: any) => {
    //     e.preventDefault();
    //     // setIsLoggedin(false);
    //     router.push(logoutLink);
    // };

    const currentUser: IfaceCurrentUser = useRecoilValue(currentUserState);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        // console.log("debug currentUser", currentUser);
        if (
            currentUser.roles?.includes("superadmin") ||
            currentUser.roles?.includes("admin") ||
            currentUser.roles?.includes("editor") ||
            currentUser.roles?.includes("happy-meal-admin") ||
            currentUser.roles?.includes("fg-admin") ||
            currentUser.roles?.includes("fgd-admin")
        ) {
            // console.log('currentUser', currentUser);
            setIsAdmin(true);
        }
        if(currentUser.id !== null && currentUser.id !== undefined) {
          // console.log('setting user id', currentUser.id);
        //   if(window.dataLayer && window.dataLayer !== undefined) {
        //     window.dataLayer.push({"user_id" : currentUser.id});
        //   }
        }
    }, [currentUser]);

    /**
     * Hook that alerts clicks outside of the passed ref
     * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
     */
    // TODO: clean up type 'any' here
    function useOutsideAlerter(ref: HTMLElement | any) {
        useEffect(() => {
            function handleClickOutside(event: MouseEvent) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target) &&
                    showAdminDropdown === true
                ) {
                    toggleAdminDropdown();
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [showAdminDropdown]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div className={styles.container} ref={wrapperRef}>
            <button
                className={styles.admintoggle}
                onClick={toggleAdminDropdown}>
                <IconDropdown />
            </button>

            {showAdminDropdown && (
                <div className={styles.dropdown}>
                    <ul>
                        <li>
                            <Link href="/profile">
                                <a>View Profile</a>
                            </Link>
                        </li>
                        {isAdmin && (
                            <li>
                                <a href={linkToCMS} id={'cms-link'}>Go To CMS</a>
                            </li>
                        )}

                        <li>
                            <a href={'/bookmarks'}>Bookmarks</a>
                        </li>

                        <li>
                            <a href={logoutLink}>Logout</a>
                        </li>

                        {/* <li className={styles.functionAdmin}>
                            <a href="#" onClick={removeAdminUI}>
                                End Impersonation
                            </a>
                        </li> */}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default AdminToggle;
